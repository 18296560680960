.home-projects-list-desktop {
  display: none;

  @include for-tablet-landscape-up {
    display: flex;
  }

  .projects {
    flex: 0 0 300px;

    .project {
      @include font-card-content;
      margin-bottom: 0.5rem;
      padding: 1rem;
      border-radius: $card-border-radius 0 0 $card-border-radius;
      opacity: 0.65;
      cursor: pointer;

      &:first-child {
        margin-top: 1rem;
      }

      &.selected, &:hover {
        background: var(--overlay-background-color);
        box-shadow: $card-shadow;
        opacity: 1.0;
      }

      h2 {
        @include font-card-title;
        margin-bottom: 0.5rem;
      }
    }

    .see-all {
      padding: 0.8rem 1rem;
      @include font-see-all;
    }
  }

  .previews {
    $padding: 0.8rem;
    flex: 1 1 auto;
    padding: $padding;
    background: var(--overlay-background-color);
    border-radius: $card-border-radius;

    .preview {
      img {
        display: block;
        width: 100%;
      }

      a {
        display: block;
        text-align: right;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem - $padding;
      }
    }
  }
}

.home-projects-list-mobile {
  @include for-tablet-landscape-up {
    display: none;
  }

  .project {
    @include font-card-content;
    margin-bottom: 3rem;

    h1 {
      @include font-card-title;
      margin-bottom: 0.5rem;
    }

    img {
      display: block;
      width: 100%;
      margin-top: 1rem;
    }

    a {
      display: block;
      text-align: right;
      margin-top: 1rem;
    }
  }

  .see-all {
    @include font-see-all;
    background-color: var(--accent-background-color);
    border-radius: $card-border-radius;
    @include animate-transition(background-color);

    a {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      height: 100%;
      padding: 2rem 1rem;
    }

    &:hover {
      background-color: var(--accent-background-hover-color);

      a {
        color: inherit;
      }
    }
  }
}
