.trainings-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  grid-template-columns: repeat(auto-fill, minmax(min(19rem, 100%), 1fr));
  gap: 1rem;

  .training {
    a {
      display: block;
      height: 100%;
      background-color: var(--overlay-background-color);
      border-radius: $card-border-radius;
      overflow: hidden;
      box-shadow: $card-shadow;
      @include animate-transition((transform, box-shadow));

      &:hover {
        transform: scale(1.02);
        box-shadow: $card-hover-shadow;
      }

      .thumb {
        padding-bottom: 53%;
        background-position: center;
        background-size: cover;
        background-color: var(--secondary-background-color);
      }

      .content {
        padding: 1.25rem;

        h1 {
          @include font-card-title;
          margin-bottom: 0.94rem;
        }

        p {
          @include font-card-content;
        }

        p.duration {
          @include font-card-note;
          margin-bottom: 0.94rem;;
        }
      }
    }
  }

  .see-all {
    @include font-see-all;
    background-color: var(--accent-background-color);
    border-radius: $card-border-radius;
    @include animate-transition(background-color);

    a {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      height: 100%;
      padding: 2rem 1rem;
    }

    &:hover {
      background-color: var(--accent-background-hover-color);

      a {
        color: inherit;
      }
    }
  }
}
