.title-block {
  max-width: 53rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
    height: 4.19rem;
  }

  h1 {
    @include font-title-1;
    margin-top: 0.63rem;
  }

  p {
    margin-top: 1.25rem;
  }
}
