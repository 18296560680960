body > footer {
  padding: 3.13rem 0;
  background-color: var(--overlay-background-color);

  h2 {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .flex-grid {
    display: flex;
    flex-wrap: wrap;
    @include grid-gap(2rem);

    > * {
      flex: 1 1 auto;
    }
  }
}
